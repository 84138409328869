<template lang="pug">
footer.footer(
  role='contentinfo'
)
  SvgAlien.footer__svg-alien

  .footer__container
    SvgUniverse.footer__container-universe
    SvgBackground.footer__container-background

    .footer__box(
      :class="{'footer__box_5': isLoggedIn}"
    )
      .footer__company
        RssUiLogo.footer__company-logo
        .footer__company-text &copy; RSS America LLC.

        .footer__socials
          a.footer__social.footer__social-icon(
            href='https://www.facebook.com/rss.podcasting'
            target='_blank'
            rel='noopener'
            aria-label='Facebook'
          )
            SocialIconFacebook.footer__social-icon
          a.footer__social.footer__social-icon(
            href='https://twitter.com/rss'
            target='_blank'
            rel='noopener'
            aria-label='Twitter'
          )
            SocialIconTwitter.footer__social-icon
          a.footer__social.footer__social-icon(
            href='https://www.linkedin.com/company/rsscom'
            target='_blank'
            rel='noopener'
            aria-label='LinkedIn'
          )
            SocialIconLinkedIn.footer__social-icon
          a.footer__social(
            href='https://www.youtube.com/rsscom/'
            target='_blank'
            rel='noopener'
            aria-label='YouTube'
          )
            SocialIconYoutube.footer__social-icon.footer__social-icon_youtube
          a.footer__social(
            href='https://www.instagram.com/rss_podcasting/'
            target='_blank'
            rel='noopener'
            aria-label='Instagram'
          )
            SocialIconInstagram.footer__social-icon.footer__social-icon_instagram

        a.footer__podcast-standards(
          target="_blank"
          href="https://podstandards.org/"
        )
          img.footer__podcast-standards-img(
            src="/podcast-standards-cert.svg"
          )

      .footer__menu
        .footer__menu-title {{ $t('modules.footline.resources_nav_title') }}
        ul.footer__menu-list
          li.footer__menu-item
            a.footer__menu-link(
              :href="staticUrl + $locatedLink('/affiliates/')"
              rel='noopener'
            )
              | {{ $t('single_words.affiliates') }}
          li.footer__menu-item
            a.footer__menu-link(
              href='https://rss.com/blog/'
              target='_blank'
              rel='noopener'
            )
              | {{ $t('modules.navigation.blog') }}
          li.footer__menu-item
            a.footer__menu-link(
              :href="$locatedLink('/press/')"
            )
              | {{ $t('modules.navigation.press') }}
          li.footer__menu-item
            a.footer__menu-link(
              :href="mediaKitLink"
            )
              | {{ $t('modules.navigation.media_kit') }}
          li.footer__menu-item
            a.footer__menu-link(
              :href='supportLink'
              target='_blank'
              rel='noopener'
            ) {{ $t('modules.navigation.support') }}

      .footer__menu
        .footer__menu-title {{ $t('single_words.features') }}
        ul.footer__menu-list
          li.footer__menu-item
            NuxtLink.footer__menu-link(
              :to="$locatedLink('/switch-to-rss/')"
              rel='noopener'
            )
              | {{ $t('modules.navigation.switch_to_rss') }}
          li.footer__menu-item
            NuxtLink.footer__menu-link(
              :to="$locatedLink('/gift-cards/')"
              rel='noopener'
            )
              | {{ $t('pages.ssrGiftCards.giftRssCom') }}

      .footer__menu
        .footer__menu-title {{ $t('modules.footline.legal_nav_title') }}
        ul.footer__menu-list
          li.footer__menu-item
            nuxt-link.footer__menu-link(:to="$locatedLink('/cookie-policy/')")
              | {{ $t('links.cookie_policy') }}
          li.footer__menu-item
            nuxt-link.footer__menu-link(:to="$locatedLink('/privacy-policy/')")
              | {{ $t('links.privacy_policy') }}
          li.footer__menu-item
            nuxt-link.footer__menu-link(:to="$locatedLink('/terms-of-service/')")
              | {{ $t('links.terms_of_service') }}

      .footer__menu.footer__community
        .footer__menu-title.footer__menu-title_icon.footer__menu-title_narrow
          RssUiLogo.footer__menu-title-icon(
            simple
          )
          template {{ $t('single_words.podcasts') }}
        span.footer__community-text(
          v-html="$t('modules.footer.community.text', { link: $locatedLink('/')})"
        )
        nuxt-link.footer__community-link.link-button-arrow-animation(
          :to="$locatedLink('/community/')"
        )
          template {{ $t('modules.footer.community.link') }}
          .link-button-arrow-animation__i
            SvgSmallArrow

      .footer__menu.footer__lang(
        v-if="!isLoggedIn"
      )
        .footer__menu-title.footer__menu-title_narrow {{ $t('sentences.choose_language') }}
        LangSwitcher
</template>

<script>
import { mapState } from 'vuex'

import SvgAlien from './svg/Alien'
import SvgUniverse from './svg/Universe'
import SvgBackground from './svg/Background'
import SvgSmallArrow from '@/components/icons/default/ArrowSmall'

import LangSwitcher from '@/components/shared/elements/lang-switcher/index'

import SocialIconTwitter from '@/components/icons/dashboard/socials/Twitter'
import SocialIconYoutube from '@/components/icons/dashboard/socials/YouTube'
import SocialIconLinkedIn from '@/components/icons/dashboard/socials/LinkedIn'
import SocialIconFacebook from '@/components/icons/dashboard/socials/Facebook'
import SocialIconInstagram from '@/components/icons/dashboard/socials/Instagram'

import { config } from '@/config'

export default {
  name: 'Footline',

  components: {
    LangSwitcher,

    SocialIconTwitter,
    SocialIconYoutube,
    SocialIconLinkedIn,
    SocialIconFacebook,
    SocialIconInstagram,

    SvgAlien,
    SvgUniverse,
    SvgBackground,
    SvgSmallArrow
  },

  props: {
    dashboard: Boolean
  },

  computed: {
    ...mapState('auth', ['isLoggedIn']),

    staticUrl () {
      return config.staticUrl
    },

    supportLink () {
      if (!config) {
        return ''
      }
      return config.supportLink + '/' + this.$i18n.locale + '/support/home'
    },

    mediaKitLink () {
      return config.mediaKitLink
    },

    isHome () {
      if (!this.$route.name) {
        return
      }

      return this.$route.name.startsWith('index_')
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  padding: 0;
  position: relative;

  &__container {
    z-index: 3;
    width: 100%;
    overflow: hidden;
    position: relative;
    background: #25163A;

    &-universe {
      right: 50%;
      z-index: 5;
      top: -83px;
      position: absolute;
      margin-right: 135px;

      @include display-less(tablet) {
        top: auto;
        bottom: 60px;
        margin-right: -80px;
      }
    }

    &-background {
      left: 50%;
      z-index: 5;
      bottom: -152px;
      position: absolute;
      margin-left: -307px;
    }
  }

  &__box {
    z-index: 10;
    display: grid;
    grid-gap: 40px;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    align-items: flex-start;
    padding: $gap * 4 $gap * 1.5;
    width: 1152px + $gap + $gap;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    &_5 {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    @include display-less(big) {
      grid-gap: $gap * 2;
    }

    @include display-less(desktop) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @include display-less(tablet) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include display-less(phablet) {
      grid-template-columns: 1fr;
    }
  }

  &__podcast-standards {
    width: 112px;
    display: flex;
    margin-top: $gap * 1.5;

    &-img {
      width: 100%;
    }

    @include display-less(smart) {
      margin-top: 40px;
    }
  }

  // Decorations
  &__svg-alien {
    left: 50%;
    top: -81px;
    z-index: 2;
    width: 82px;
    height: 140px;
    margin-left: 35vw;
    position: absolute;

    @include display-less(tablet) {
      left: auto;
      right: $gap;
      margin-left: 0;
    }
  }

  &__company {
    &-logo {
      width: 105px;
    }

    &-text {
      color: #FFFFFF;
      font-size: 16px;
      line-height: 150%;
      margin-top: $gap * 0.5;
    }

    @include display-less(phablet) {
      order: 10;
    }
  }

  &__socials {
    display: flex;
    grid-gap: $gap * 0.75;
    align-items: center;
    justify-content: center;
  }

  &__social {
    margin-top: $gap * 1.5;

    &-icon {
      width: 24px;
      height: 24px;

      &_youtube {
        width: 32px;
        transform: translateY(3px);
      }

      &_instagram {
        transform: translateY(3px);
      }
    }
  }

  // Menu
  &__menu {
    &-title {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      line-height: 150%;
      position: relative;
      margin-bottom: $gap;

      &_narrow {
        margin-bottom: 10px;
      }

      &_icon {
        padding-left: $gap * 1.5;
      }

      &-icon {
        left: 0;
        top: -3px;
        width: $gap;
        position: absolute;
      }
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: $gap;
      }
    }

    &-link {
      color: #fff;
      font-size: 16px;
      line-height: 150%;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  // Community block
  &__community {
    display: flex;
    flex-direction: column;

    &-text {
      color: #FFFFFF;
      font-size: 14px;
      line-height: 150%;
      margin-bottom: 10px;
      /deep/ a {
        color: #FFFFFF;
      }
    }

    &-link {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: bold;
      line-height: 24px;
      position: relative;
      text-decoration: none;
    }
  }
}
</style>
